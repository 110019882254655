<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">REMITTANCES</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-layout col>
          <v-flex xs12 md3>
            <v-form ref="form" class="multi-col-validation">
              <v-select
                v-model="year_of"
                class="mx-2"
                dense
                outlined
                label="Year Of"
                :items="year_of_items"
                item-value="year"
                item-text="year"
                :rules="rules.combobox_rule"
                @change="selected_months"
              ></v-select>
              <v-select
                v-model="month_of"
                class="mx-2"
                dense
                outlined
                label="Month Of"
                :items="month_of_items"
                item-value="month_of"
                item-text="month_of"
                :rules="rules.combobox_rule"
                @change="selected_data"
              ></v-select>
              <v-select
                v-model="view_by"
                class="mx-2"
                dense
                outlined
                label="View By"
                :items="['Members Payment','Loan Payments','Loan Deductions','NMAPI Share']"
                :rules="rules.combobox_rule"
                @change="selected_data"
              ></v-select>
            </v-form>
            <v-data-table dense
                          :headers="headers3"
                          :items="data_items"
            >
              <template v-slot:item="{ item }">
                <tr
                  @click="activerow(item)"
                  :class="{'info white--text': item.id===selectedDepositId}"
                >
                  <td>
                    {{ item.id }}
                  </td>
                  <td>
                    {{ item.date_of_deposit }}
                  </td>
                  <td>
                    {{ item.time_of_deposit }}
                  </td>
                  <td>
                    {{ item.bank_depositories!=null?item.bank_depositories.bank_code:'' }}
                  </td>
                  <td>
                    {{ formatPrice(item.amount) }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
          <v-flex xs12 md9 class="mx-2">
            <h2>Total: P {{total_amount}}</h2>
            <v-data-table dense
                          :headers="headers2"
                          :items="data_items2"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title v-if="!printingss && data_items2.length > 0">Print
                  </v-toolbar-title>
                  <v-icon
                    v-if="!printingss && data_items2.length > 0"
                    color="success"
                    @click="print_data()"
                  >
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  <v-progress-circular
                    color="info"
                    indeterminate
                    v-if="printingss"
                  ></v-progress-circular>
                </v-toolbar>
              </template>
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{ item.name }}
                  </td>
                  <td>
                    {{ item.type }}
                  </td>
                  <td>
                    {{ formatPrice(item.amount) }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiAccountOutline,
    mdiAccountCashOutline,
    mdiInformationOutline,
    mdiPrinter
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      alert: false,
      saving_data: false,
      printingss: false,
      alert_message: '',
      month_of: '',
      year_of: '',
      view_by: '',
      total_amount: '0',
      headers2: [
        {text: 'Name', value: 'date_of_deposit', sortable: false},
        {text: 'Type', value: 'tin', sortable: false},
        {text: 'Amount', value: 'tin', sortable: false},
      ],
      headers3: [
        {text: 'ID', value: 'id', sortable: false},
        {text: 'Date', value: 'date_of_deposit', sortable: false},
        {text: 'Time', value: 'tin', sortable: false},
        {text: 'Bank', value: 'tin', sortable: false},
        {text: 'Amount', value: 'tin', sortable: false},
      ],
      data_items: [],
      data_items2: [],
      year_of_items: [],
      month_of_items: [],
      selectedDepositId: -1,
      current_page: '',
      remittances_category: '',
      depo_info: {},
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiPrinter,
          mdiInformationOutline,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_months()
        .then(response => {
          this.year_of_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('deposit_slips', ['initialize_months', 'view_remittances']),
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_months() {
        var index = this.year_of_items
          .map(function (x) {
            return x.year
          })
          .indexOf(this.year_of)
        if (index != -1) {
          this.month_of_items = this.year_of_items[index].months
        }
      },
      selected_data() {
        if (this.$refs.form.validate()) {
          this.selectedDepositId = -1
          this.data_items = []
          this.data_items2 = []
          this.total_amount = '0'
          this.view_remittances({
            is_loan: this.view_by === 'Loan Payments' ? 1 : 0,
            is_deduction_payment: this.view_by === 'Loan Deductions' ? 1 : 0,
            is_nmapi_share: this.view_by === 'NMAPI Share' ? 1 : 0,
            month_of: this.month_of
          })
            .then(response => {
              this.data_items = response.data
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      activerow(value) {
        this.depo_info = value
        this.total_amount = '0'
        this.data_items2 = []
        this.selectedDepositId = value.id;
        var tto = 0;
        var dedd = [];

        if (this.view_by === 'Loan Payments') {
          value.loan_payment.forEach(function (item) {
            dedd.push({
              name: item.loan_data.member.company_name,
              type: 'LOAN PAYMENT',
              amount: (parseFloat(item.amount) + parseFloat(item.interest) + parseFloat(item.penalty))
            })
            tto += (parseFloat(item.amount) + parseFloat(item.interest) + parseFloat(item.penalty))
          });
        } else if (this.view_by === 'Loan Deductions') {
          value.loan_deduction_payment.forEach(function (item) {
            dedd.push({
              name: item.loan_data.member.company_name,
              type: item.particulars,
              amount: (parseFloat(item.amount))
            })
            tto += (parseFloat(item.amount))
          });
        } else if (this.view_by === 'NMAPI Share') {
          value.nmapi_share_payment.forEach(function (item) {
            dedd.push({
              name: item.name,
              type: 'NMAPI SHARE',
              amount: (parseFloat(item.amount))
            })
            tto += (parseFloat(item.amount))
          });
        } else {
          value.members_payment.forEach(function (item) {
            dedd.push({
              name: item.member.company_name,
              type: 'MEMBERS PAYMENT',
              amount: item.amount
            })
            tto += parseFloat(item.amount)
          });
        }
        this.data_items2 = dedd
        this.total_amount = this.formatPrice(tto)
      },
      print_data() {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form.validate()) {
          var imgData = this.company_logo
          var payments_array = []
          var widths = []
          var payment = 0
          var info_array = []

          widths = [200, 50, 60]
          payments_array.push([
            {text: 'Name', alignment: 'left', style: 'main_info',},
            {text: 'Type', alignment: 'left', style: 'main_info',},
            {text: 'Amount', alignment: 'left', style: 'main_info'},
          ])
          this.data_items2.forEach(function (item) {
            payment += parseFloat(item.amount)
            payments_array.push([
              {
                text: item.name,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: item.type,
                alignment: 'left',
                style: 'tableExample2'
              },
              {
                text: (item.amount / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left',
                style: 'tableExample2'
              },
            ])
          })

          payments_array.push([
            {
              text: '',
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: 'TOTAL: ',
              alignment: 'left',
              style: 'tableExample2'
            },
            {
              text: (payment / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left',
              style: 'tableExample2'
            },
          ])
          info_array = [
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    'Month of: ',
                    {
                      text: this.month_of,
                      style: 'main_data',
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    'Deposit ID: ',
                    {
                      text: this.depo_info.id,
                      style: 'main_data', color: 'red',
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    'Date Deposited: ',
                    {
                      text: this.depo_info.date_of_deposit,
                      style: 'main_data',
                    },
                  ],
                },
                {text: ''},
                {
                  style: 'main_info',
                  text: [
                    'Bank: ',
                    {
                      text: this.depo_info.bank_depositories.bank_code,
                      style: 'main_data',
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {
                  style: 'main_info',
                  text: [
                    'Time Deposited: ',
                    {
                      text: this.depo_info.time_of_deposit,
                      style: 'main_data',
                    },
                  ],
                },
                {text: ''},
                {
                  style: 'main_info',
                  text: [
                    'Amount: ',
                    {
                      text: this.formatPrice(this.depo_info.amount),
                      style: 'main_data', color: 'green',
                    },
                  ],
                },
              ],
            },
          ]

          //call pdfmake
          var pdfMake = require('pdfmake/build/pdfmake.js')
          if (pdfMake.vfs == undefined) {
            var pdfFonts = require('pdfmake/build/vfs_fonts.js')
            pdfMake.vfs = pdfFonts.pdfMake.vfs
          }
          var docDefinition = {
            pageSize: {
              width: 612,
              height: 936,
            },
            pageOrientation: 'portrait',
            content: [
              {
                columns: [
                  {image: this.company_logo, width: 54, height: 54, style: 'logo'},
                  {
                    stack: [
                      'NATIONAL MORTUARY ASSOCIATION OF THE PHILIPPINES INC.',
                      {
                        text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                        style: 'printed_label',
                      },
                    ],
                    style: 'header'
                  },
                ]
              },
              '_______________________________________________________________________________________________',
              {
                stack: [
                  {
                    columns: [
                      {
                        text: 'Remittances of - ' + this.view_by,
                        style: {
                          fontSize: 16,
                          alignment: 'center',
                          bold: true,
                          color: 'blue',
                        },
                      },
                    ],
                  },
                ],
              },
              {
                columns: [{text: ' '}],
              },
              {
                columns: [{text: ' '}],
              },
              {
                stack: info_array,
              },
              {
                columns: [{text: ' '}],
              },
              {
                table: {
                  widths: widths,
                  body: payments_array,
                  style: {fontSize: 1},
                },
              },
              {
                stack: [
                  {
                    columns: [{text: ' '}],
                  },
                  {
                    columns: [{text: ' '}],
                  },
                  {
                    columns: [
                      {
                        text: 'PROCESSED BY',
                        style: 'main_data_2',
                      },
                      {
                        text: 'DEPARTMENT RECEIVER',
                        style: 'main_data_2',
                      },
                      {
                        text: 'RECEIVED BY',
                        style: 'main_data_2',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: this.name,
                        style: 'main_data_2',
                      },
                      {
                        text: ' ',
                      },
                      {
                        text: '',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: '_____________________',
                      },
                      {
                        text: '_____________________',
                      },
                      {
                        text: '_____________________',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: this.position,
                        style: 'main_data_2',
                      },
                      {
                        text: 'Signature Over Printed Name',
                        style: 'main_data_2',
                      },
                      {
                        text: 'Signature Over Printed Name',
                        style: 'main_data_2',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'VERIFIED BY',
                        style: 'main_data_2',
                      },
                      {
                        text: '',
                      },
                      {
                        text: '',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: '_________________________________________',
                      },
                      {
                        text: ' ',
                      },
                      {
                        text: ' ',
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        text: 'BOARD OF DIRECTOR',
                        style: 'main_data_2',
                      },
                      {
                        text: '',
                      },
                      {
                        text: '',
                      },
                    ],
                  },
                ],
              },
            ],
            footer: {
              // columns: [
              //     {
              //         text: 'Generated with Goodlife Savings & Credit Cooperative System',
              //         alignment: 'center',
              //         style: 'tableExample'
              //     }
              // ]
            },
            styles: {
              tableExample: {
                fontSize: 9,
              },
              tableExample2: {
                fontSize: 7,
              },
              header: {
                fontSize: 21,
                bold: true,
                alignment: 'left',
                margin: [6, 6, 0, 5], //[left, top, right, bottom]
              },
              subheader: {
                fontSize: 11,
              },
              logo: {
                alignment: 'center',
                margin: [0, 0, 0, 0], //[left, top, right, bottom]
              },
              printed_label: {
                alignment: 'right',
                fontSize: 9,
                margin: [0, 0, 0, 0], //[left, top, right, bottom]
              },
              main_data: {
                margin: [0, 2, 0, 2],
                fontSize: 11,
                bold: true,
                alignment: 'left',
              },
              main_data_2: {
                margin: [0, 2, 0, 2],
                fontSize: 8,
                bold: true,
                alignment: 'left',
              },
              main_info: {
                margin: [0, 2, 0, 2],
                fontSize: 10,
                alignment: 'left',
              },
            },
          }
          pdfMake.createPdf(docDefinition).open()
          this.saving_data = false
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      }
    }
  }
</script>
